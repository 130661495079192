import React, { useEffect, useState } from 'react';
import Input from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import { motion } from 'framer-motion';
import { API } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import Spinner from '../components/Spinner';
import Alert from '../components/Alert';

const myAPI = "api8c740aeb"
const path = "/invoices"


const initialFormState = {
    name: "",
    email: "",
    number: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    type: "Himayat (4kg Box) - $50 / Box",
    numBoxes: "1",
}

const types = ["Himayat (4kg Box) - $50 / Box", "Nuzvid Cheruku Rasalu (4kg Box) - $45 / Box", "Banganapalli (4kg Box) - $42 / Box"];

const Home = () => {
    const [formFields, setFormFields] = React.useState(initialFormState);
    const { name, email, address, type, numBoxes, city, state, zip } = formFields;
    const [phoneNum, setPhoneNum] = useState("");
    const { handleSubmit, formState } = useForm();
    const { isSubmitting } = formState;
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess ] = useState(false);
    const [cid, setCid] = useState("");

    function onChange(e) {
        e.persist();
        setFormFields(() => ({ ...formFields, [e.target.name]: e.target.value }));
    };

    async function logInvoice() {
        if (!name || !email || !city || !state || !zip) {
            return;
        }

        let response;

        try {
            formFields.number = phoneNum;

            const myInit = {
                body: formFields,
                headers: {}
            };

            try {
                response = await API.post(myAPI, path, myInit);
            } catch (e) {
                setShowError(true);
                return;
            }

            if (response.message === "success") {
                setFormFields(initialFormState);
                setPhoneNum("");
                setCid(response.cid);
                setShowSuccess(true);
            }
        } catch {
            console.log("Error logging invoice");
        }

    };

    return (
        <div className="relative flex flex-col items-center mx-5 h-screen my-20 md:mx-auto">
            <motion.div initial={{ opacity: 0, x: '-100vw' }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1, type: "spring" }} >
                <div className="max-w-sm w-full space-y-6 items-center md:max-w-md">
                    <div className="header flex justify-center">
                        <h1 className="text-white text-center text-6xl font-bold drop-shadow-md">Spices and Slices</h1>
                    </div>
                    <div className="text-white text-center text-brandYellow">
                        <h2 className="text-2xl font-bold drop-shadow-md">Order Form</h2>
                        <p className="drop-shadow-sm">Each box contains up to 9 mangos</p>
                    </div>
                    {showError && <Alert type="Error" message="Error submitting order. There are not enough of the selected item." isError={true} cid="" />}
                    {showSuccess && <Alert type="Success" message="Order submitted successfully. You will receive an email shortly with your order details. Your order id is" isError={false} cid={cid} />}
                    <form onSubmit={handleSubmit(logInvoice)}>
                        <div className="mx-2 mb-4">
                            <label for="name" className="block mb-2 text-md font-medium text-white drop-shadow-md">Name</label>
                            <input type="text" name="name" id="name" className={inputStyle} value={name} onChange={onChange} required />
                        </div>
                        <div className="mx-2 mb-4">
                            <label for="email" className="block mb-2 text-md font-medium text-white drop-shadow-md">Email</label>
                            <input type="email" name="email" id="email" className={inputStyle} value={email} onChange={onChange} required />
                        </div>
                        <div className="mx-2 mb-4">
                            <label for="phoneNum" className="block mb-2 text-md font-medium text-white drop-shadow-md">Mobile Number</label>
                            <Input country="US" placeholder="(123)-456-7890" className={inputStyle} value={phoneNum} onChange={setPhoneNum} required />
                        </div>
                        <div className="mx-2 mb-4">
                            <label for="address" className="block mb-2 text-md font-medium text-white drop-shadow-md">Address</label>
                            <input type="text" name="address" id="address" className={inputStyle} value={address} placeholder="1400 Place St." onChange={onChange} />
                        </div>
                        <div className="mx-2 mb-4">
                            <label for="city" className="block mb-2 text-md font-medium text-white drop-shadow-md">City</label>
                            <input type="text" name="city" id="city" className={inputStyle} value={city} placeholder="Chantilly" onChange={onChange} required />
                        </div>
                        <div className="grid grid-cols-4 gap-4 mb-4 mx-2">
                            <div className="col-span-3">
                                <label for="zip" className="block mb-2 text-md font-medium text-white drop-shadow-md">ZIP</label>
                                <input type="text" name="zip" id="zip" className={inputStyle} placeholder="20152" value={zip} onChange={onChange} required />
                            </div>
                            <div>
                                <label for="state" className="block mb-2 text-md font-medium text-white drop-shadow-md">State</label>
                                <input type="text" name="state" id="state" className={inputStyle} placeholder="VA" value={state} onChange={onChange} required />
                            </div>
                        </div>
                        <div className="grid grid-cols-4 gap-4 mb-4 mx-2">
                            <div className="col-span-3">
                                <label for="type" className="block mb-2 text-md font-medium text-white drop-shadow-md">Type</label>
                                <select id="type" name="type" className={inputStyle} value={type} onChange={onChange}>
                                    {types.map((type, i) => <option key={i} value={type} onChange={onChange}>{type}</option>)}
                                </select>
                            </div>
                            <div>
                                <label for="numBoxes" className="block mb-2 text-md font-medium text-white drop-shadow-md">Amount</label>
                                <select id="numBoxes" name="numBoxes" className={inputStyle} value={numBoxes} onChange={onChange}>
                                    {[...Array(10)].map((_, i) => <option key={i} value={i + 1}>{i + 1}</option>)}
                                </select>
                            </div>
                        </div>


                        <div className="mb-4 text-white text-center">
                            <p>
                                For inquiries about bulk orders, please email <a href="mailto: customersupport@spicesandslices.com" className="text-brandDarkBlue transition duration-500 hover:text-brandBlue">customersupport@spicesandslices.com</a> or contact us at <a href="tel:571-639-5188" className="inline-block text-brandDarkBlue transition duration-500 hover:text-brandBlue">571-639-5188.</a>
                            </p>
                        </div>
                        <div className="text-center mb-4">
                            <button disabled={isSubmitting} type="submit" className="mx-auto flex items-center justify-center bg-brandDarkBlue text-white font-bold py-2 px-4 rounded transition duration-500 drop-shadow-md focus:outline-none focus:shadow-outline hover:bg-brandBlue hover:drop-shadow-lg">{isSubmitting && (<Spinner />)}Submit</button>
                        </div>
                    </form>
                </div>
            </motion.div>
        </div>
    );
};

export default Home;

const inputStyle = "bg-white border border-gray-300 text-gray-900 text-sm rounded-lg drop-shadow-md transition duration-500 hover:drop-shadow-xl focus:ring-brandOrange focus:outline-none block w-full p-2.5";